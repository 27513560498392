<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {},
  props: {
    token: {
      type: String,
      required: true,
    },
    idComprobante: {
      type: String,
      required: true,
    },
  },
  created() {
    const userData = {
      email: '',
      // password: '',
      username: '',
      fullName: '',
      avatar: null,
      role: 'developer',
      ability: [
        {
          action: 'manage',
          subject: 'all',
        },
      ],
    }
    this.setUserData(userData)
    this.setUserToken(this.token)
    this.setIdComprobante(this.idComprobante)
    this.$ability.update(userData.ability)
    this.$router.push({ name: 'carta-porte' })
    this.fetchComprobante(this.idComprobante)
  },
  methods: {
    ...mapActions(['setUserData', 'setUserToken', 'setIdComprobante']),
    ...mapActions('CartaPorte', ['fetchComprobante']),
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
